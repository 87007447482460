@keyframes zoomInOn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* line 3, src/common/scss/sprites_countries.scss */
.bg-afuhan {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -55px -281px;
}

/* line 8, src/common/scss/sprites_countries.scss */
.bg-aihouse {
  width: 25px;
  height: 25px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -55px -10px;
}

/* line 13, src/common/scss/sprites_countries.scss */
.bg-aiji {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -100px -281px;
}

/* line 18, src/common/scss/sprites_countries.scss */
.bg-airlan {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -145px -281px;
}

/* line 23, src/common/scss/sprites_countries.scss */
.bg-aiseby {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -190px -281px;
}

/* line 28, src/common/scss/sprites_countries.scss */
.bg-aishny {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -235px -281px;
}

/* line 33, src/common/scss/sprites_countries.scss */
.bg-alianqiu {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -280px -281px;
}

/* line 38, src/common/scss/sprites_countries.scss */
.bg-aman {
  width: 25px;
  height: 13px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -557px -422px;
}

/* line 43, src/common/scss/sprites_countries.scss */
.bg-andaor {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -332px -10px;
}

/* line 48, src/common/scss/sprites_countries.scss */
.bg-angla {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -332px -45px;
}

/* line 53, src/common/scss/sprites_countries.scss */
.bg-arbny {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -332px -80px;
}

/* line 58, src/common/scss/sprites_countries.scss */
.bg-arjly {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -332px -115px;
}

/* line 63, src/common/scss/sprites_countries.scss */
.bg-asaibaijiang {
  width: 25px;
  height: 16px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -235px -244px;
}

/* line 68, src/common/scss/sprites_countries.scss */
.bg-babaduosi {
  width: 25px;
  height: 17px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -55px -132px;
}

/* line 73, src/common/scss/sprites_countries.scss */
.bg-bahama {
  width: 25px;
  height: 16px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -287px -10px;
}

/* line 78, src/common/scss/sprites_countries.scss */
.bg-baie {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -332px -150px;
}

/* line 83, src/common/scss/sprites_countries.scss */
.bg-bajisitan {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -332px -185px;
}

/* line 88, src/common/scss/sprites_countries.scss */
.bg-balagui {
  width: 25px;
  height: 17px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -100px -132px;
}

/* line 93, src/common/scss/sprites_countries.scss */
.bg-balesitan {
  width: 25px;
  height: 13px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -557px -455px;
}

/* line 98, src/common/scss/sprites_countries.scss */
.bg-balin {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -332px -220px;
}

/* line 103, src/common/scss/sprites_countries.scss */
.bg-baojly {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -332px -255px;
}

/* line 108, src/common/scss/sprites_countries.scss */
.bg-baxi {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -10px -317px;
}

/* line 113, src/common/scss/sprites_countries.scss */
.bg-bbd {
  width: 25px;
  height: 17px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -145px -132px;
}

/* line 118, src/common/scss/sprites_countries.scss */
.bg-beinin {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -55px -317px;
}

/* line 123, src/common/scss/sprites_countries.scss */
.bg-bilishi {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -100px -317px;
}

/* line 128, src/common/scss/sprites_countries.scss */
.bg-bingdao {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -145px -317px;
}

/* line 133, src/common/scss/sprites_countries.scss */
.bg-bishao {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -190px -317px;
}

/* line 138, src/common/scss/sprites_countries.scss */
.bg-bjnfs {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -235px -317px;
}

/* line 143, src/common/scss/sprites_countries.scss */
.bg-blz {
  width: 25px;
  height: 17px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -197px -10px;
}

/* line 148, src/common/scss/sprites_countries.scss */
.bg-bociwana {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -280px -317px;
}

/* line 153, src/common/scss/sprites_countries.scss */
.bg-bohei {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -325px -317px;
}

/* line 158, src/common/scss/sprites_countries.scss */
.bg-bolan {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -377px -10px;
}

/* line 163, src/common/scss/sprites_countries.scss */
.bg-budan {
  width: 25px;
  height: 17px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -197px -47px;
}

/* line 168, src/common/scss/sprites_countries.scss */
.bg-bulongdi {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -377px -45px;
}

/* line 173, src/common/scss/sprites_countries.scss */
.bg-chaoxian {
  width: 25px;
  height: 13px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -557px -488px;
}

/* line 178, src/common/scss/sprites_countries.scss */
.bg-chdjny {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -377px -80px;
}

/* line 183, src/common/scss/sprites_countries.scss */
.bg-danmai {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -377px -115px;
}

/* line 188, src/common/scss/sprites_countries.scss */
.bg-dbg {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -377px -150px;
}

/* line 193, src/common/scss/sprites_countries.scss */
.bg-dmnike {
  width: 32px;
  height: 16px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -100px -10px;
}

/* line 198, src/common/scss/sprites_countries.scss */
.bg-duoge {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -377px -185px;
}

/* line 203, src/common/scss/sprites_countries.scss */
.bg-duominijia {
  width: 25px;
  height: 16px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -287px -46px;
}

/* line 208, src/common/scss/sprites_countries.scss */
.bg-egde {
  width: 32px;
  height: 16px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -100px -46px;
}

/* line 213, src/common/scss/sprites_countries.scss */
.bg-eltly {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -377px -220px;
}

/* line 218, src/common/scss/sprites_countries.scss */
.bg-eluosi {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -377px -255px;
}

/* line 223, src/common/scss/sprites_countries.scss */
.bg-fandigang {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -377px -290px;
}

/* line 228, src/common/scss/sprites_countries.scss */
.bg-feiji {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -10px -352px;
}

/* line 233, src/common/scss/sprites_countries.scss */
.bg-feilubing {
  width: 25px;
  height: 13px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -10px -527px;
}

/* line 238, src/common/scss/sprites_countries.scss */
.bg-fenlan {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -55px -352px;
}

/* line 243, src/common/scss/sprites_countries.scss */
.bg-flg_argentina_sm {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -100px -352px;
}

/* line 248, src/common/scss/sprites_countries.scss */
.bg-flg_australia_sm {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -145px -352px;
}

/* line 253, src/common/scss/sprites_countries.scss */
.bg-flg_austria_sm {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -190px -352px;
}

/* line 258, src/common/scss/sprites_countries.scss */
.bg-flg_bolivia_sm {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -235px -352px;
}

/* line 263, src/common/scss/sprites_countries.scss */
.bg-flg_brazil_sm {
  width: 25px;
  height: 18px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -152px -88px;
}

/* line 268, src/common/scss/sprites_countries.scss */
.bg-flg_canada_sm {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -280px -352px;
}

/* line 273, src/common/scss/sprites_countries.scss */
.bg-flg_chile_sm {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -325px -352px;
}

/* line 278, src/common/scss/sprites_countries.scss */
.bg-flg_china_sm {
  width: 25px;
  height: 17px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -197px -84px;
}

/* line 283, src/common/scss/sprites_countries.scss */
.bg-flg_colombia_sm {
  width: 25px;
  height: 14px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -557px -150px;
}

/* line 288, src/common/scss/sprites_countries.scss */
.bg-flg_costarica_sm {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -370px -352px;
}

/* line 293, src/common/scss/sprites_countries.scss */
.bg-flg_elsavador_sm {
  width: 25px;
  height: 16px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -287px -82px;
}

/* line 298, src/common/scss/sprites_countries.scss */
.bg-flg_france_sm {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -10px -387px;
}

/* line 303, src/common/scss/sprites_countries.scss */
.bg-flg_germany_sm {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -55px -387px;
}

/* line 308, src/common/scss/sprites_countries.scss */
.bg-flg_guetamala_sm {
  width: 25px;
  height: 14px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -557px -184px;
}

/* line 313, src/common/scss/sprites_countries.scss */
.bg-flg_honduras_sm {
  width: 25px;
  height: 14px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -557px -218px;
}

/* line 318, src/common/scss/sprites_countries.scss */
.bg-flg_mashr {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -100px -387px;
}

/* line 323, src/common/scss/sprites_countries.scss */
.bg-flg_mexico_sm {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -145px -387px;
}

/* line 328, src/common/scss/sprites_countries.scss */
.bg-flg_netherlands_sm {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -190px -387px;
}

/* line 333, src/common/scss/sprites_countries.scss */
.bg-flg_newzealand_sm {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -235px -387px;
}

/* line 338, src/common/scss/sprites_countries.scss */
.bg-flg_nicaragua_sm {
  width: 25px;
  height: 14px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -557px -252px;
}

/* line 343, src/common/scss/sprites_countries.scss */
.bg-flg_panama_sm {
  width: 25px;
  height: 14px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -557px -286px;
}

/* line 348, src/common/scss/sprites_countries.scss */
.bg-flg_peru_sm {
  width: 25px;
  height: 14px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -557px -320px;
}

/* line 353, src/common/scss/sprites_countries.scss */
.bg-flg_portugal_sm {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -280px -387px;
}

/* line 358, src/common/scss/sprites_countries.scss */
.bg-flg_romania_sm {
  width: 25px;
  height: 17px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -197px -121px;
}

/* line 363, src/common/scss/sprites_countries.scss */
.bg-flg_spain_sm {
  width: 25px;
  height: 19px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -152px -10px;
}

/* line 368, src/common/scss/sprites_countries.scss */
.bg-flg_uk_sm {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -325px -387px;
}

/* line 373, src/common/scss/sprites_countries.scss */
.bg-flg_uruguay_sm {
  width: 25px;
  height: 14px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -557px -354px;
}

/* line 378, src/common/scss/sprites_countries.scss */
.bg-flg_usa_sm {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -370px -387px;
}

/* line 383, src/common/scss/sprites_countries.scss */
.bg-flg_venezuela_sm {
  width: 25px;
  height: 13px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -55px -527px;
}

/* line 388, src/common/scss/sprites_countries.scss */
.bg-fodej {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -422px -10px;
}

/* line 393, src/common/scss/sprites_countries.scss */
.bg-gangguo {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -422px -45px;
}

/* line 398, src/common/scss/sprites_countries.scss */
.bg-gbya {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -422px -80px;
}

/* line 403, src/common/scss/sprites_countries.scss */
.bg-gelinnada {
  width: 33px;
  height: 16px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -10px -60px;
}

/* line 408, src/common/scss/sprites_countries.scss */
.bg-gelujiya {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -422px -115px;
}

/* line 413, src/common/scss/sprites_countries.scss */
.bg-guba {
  width: 31px;
  height: 16px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -62px -96px;
}

/* line 418, src/common/scss/sprites_countries.scss */
.bg-guiyana {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -422px -150px;
}

/* line 423, src/common/scss/sprites_countries.scss */
.bg-haidi {
  width: 25px;
  height: 17px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -10px -170px;
}

/* line 428, src/common/scss/sprites_countries.scss */
.bg-hanguo {
  width: 25px;
  height: 17px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -55px -170px;
}

/* line 433, src/common/scss/sprites_countries.scss */
.bg-hasakesitan {
  width: 25px;
  height: 13px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -100px -527px;
}

/* line 438, src/common/scss/sprites_countries.scss */
.bg-hongkong {
  width: 25px;
  height: 16px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -287px -118px;
}

/* line 443, src/common/scss/sprites_countries.scss */
.bg-jbbw {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -422px -185px;
}

/* line 448, src/common/scss/sprites_countries.scss */
.bg-jiana {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -422px -220px;
}

/* line 453, src/common/scss/sprites_countries.scss */
.bg-jianpuzai {
  width: 25px;
  height: 16px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -287px -154px;
}

/* line 458, src/common/scss/sprites_countries.scss */
.bg-jiapeng {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -422px -255px;
}

/* line 463, src/common/scss/sprites_countries.scss */
.bg-jibuti {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -422px -290px;
}

/* line 468, src/common/scss/sprites_countries.scss */
.bg-jieke {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -422px -325px;
}

/* line 473, src/common/scss/sprites_countries.scss */
.bg-jiererjisitan {
  width: 16px;
  height: 11px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -63px -60px;
}

/* line 478, src/common/scss/sprites_countries.scss */
.bg-jineiya {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -422px -360px;
}

/* line 483, src/common/scss/sprites_countries.scss */
.bg-jlbs {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -10px -422px;
}

/* line 488, src/common/scss/sprites_countries.scss */
.bg-kamailong {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -55px -422px;
}

/* line 493, src/common/scss/sprites_countries.scss */
.bg-kataer {
  width: 25px;
  height: 13px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -145px -527px;
}

/* line 498, src/common/scss/sprites_countries.scss */
.bg-kemoluo {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -100px -422px;
}

/* line 503, src/common/scss/sprites_countries.scss */
.bg-keniya {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -145px -422px;
}

/* line 508, src/common/scss/sprites_countries.scss */
.bg-ketediwa {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -190px -422px;
}

/* line 513, src/common/scss/sprites_countries.scss */
.bg-keweite {
  width: 25px;
  height: 13px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -190px -527px;
}

/* line 518, src/common/scss/sprites_countries.scss */
.bg-kldy {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -235px -422px;
}

/* line 523, src/common/scss/sprites_countries.scss */
.bg-laisuotuo {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -280px -422px;
}

/* line 528, src/common/scss/sprites_countries.scss */
.bg-laowo {
  width: 25px;
  height: 17px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -100px -170px;
}

/* line 533, src/common/scss/sprites_countries.scss */
.bg-latwy {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -325px -422px;
}

/* line 538, src/common/scss/sprites_countries.scss */
.bg-libaneng {
  width: 25px;
  height: 17px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -145px -170px;
}

/* line 543, src/common/scss/sprites_countries.scss */
.bg-libia {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -370px -422px;
}

/* line 548, src/common/scss/sprites_countries.scss */
.bg-libiliya {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -415px -422px;
}

/* line 553, src/common/scss/sprites_countries.scss */
.bg-liezdsd {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -467px -10px;
}

/* line 558, src/common/scss/sprites_countries.scss */
.bg-litaow {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -467px -45px;
}

/* line 563, src/common/scss/sprites_countries.scss */
.bg-lusbao {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -467px -80px;
}

/* line 568, src/common/scss/sprites_countries.scss */
.bg-luwangda {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -467px -115px;
}

/* line 573, src/common/scss/sprites_countries.scss */
.bg-macao {
  width: 25px;
  height: 16px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -287px -190px;
}

/* line 578, src/common/scss/sprites_countries.scss */
.bg-maerdaifu {
  width: 25px;
  height: 17px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -190px -170px;
}

/* line 583, src/common/scss/sprites_countries.scss */
.bg-malaixiya {
  width: 25px;
  height: 13px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -235px -527px;
}

/* line 588, src/common/scss/sprites_countries.scss */
.bg-malawei {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -467px -150px;
}

/* line 593, src/common/scss/sprites_countries.scss */
.bg-mali {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -467px -185px;
}

/* line 598, src/common/scss/sprites_countries.scss */
.bg-maqidun {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -467px -220px;
}

/* line 603, src/common/scss/sprites_countries.scss */
.bg-mart {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -467px -255px;
}

/* line 608, src/common/scss/sprites_countries.scss */
.bg-mdjsj {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -467px -290px;
}

/* line 613, src/common/scss/sprites_countries.scss */
.bg-memgjiala {
  width: 25px;
  height: 16px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -287px -226px;
}

/* line 618, src/common/scss/sprites_countries.scss */
.bg-menggu {
  width: 25px;
  height: 13px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -280px -527px;
}

/* line 623, src/common/scss/sprites_countries.scss */
.bg-miandian {
  width: 25px;
  height: 14px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -557px -388px;
}

/* line 628, src/common/scss/sprites_countries.scss */
.bg-minzhugg {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -467px -325px;
}

/* line 633, src/common/scss/sprites_countries.scss */
.bg-mklnxy {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -467px -360px;
}

/* line 638, src/common/scss/sprites_countries.scss */
.bg-mlqs {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -467px -395px;
}

/* line 643, src/common/scss/sprites_countries.scss */
.bg-mltny {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -10px -457px;
}

/* line 648, src/common/scss/sprites_countries.scss */
.bg-molog {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -55px -457px;
}

/* line 653, src/common/scss/sprites_countries.scss */
.bg-monage {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -100px -457px;
}

/* line 658, src/common/scss/sprites_countries.scss */
.bg-mordw {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -145px -457px;
}

/* line 663, src/common/scss/sprites_countries.scss */
.bg-msbk {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -190px -457px;
}

/* line 668, src/common/scss/sprites_countries.scss */
.bg-nanfei {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -235px -457px;
}

/* line 673, src/common/scss/sprites_countries.scss */
.bg-naolu {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -280px -457px;
}

/* line 678, src/common/scss/sprites_countries.scss */
.bg-niboer {
  width: 25px;
  height: 30px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -10px -10px;
}

/* line 683, src/common/scss/sprites_countries.scss */
.bg-niriliya {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -325px -457px;
}

/* line 688, src/common/scss/sprites_countries.scss */
.bg-nirir {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -370px -457px;
}

/* line 693, src/common/scss/sprites_countries.scss */
.bg-nmby {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -415px -457px;
}

/* line 698, src/common/scss/sprites_countries.scss */
.bg-nslf {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -460px -457px;
}

/* line 703, src/common/scss/sprites_countries.scss */
.bg-nuowei {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -512px -10px;
}

/* line 708, src/common/scss/sprites_countries.scss */
.bg-palao {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -512px -45px;
}

/* line 713, src/common/scss/sprites_countries.scss */
.bg-riben {
  width: 25px;
  height: 17px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -242px -10px;
}

/* line 718, src/common/scss/sprites_countries.scss */
.bg-ruidian {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -512px -80px;
}

/* line 723, src/common/scss/sprites_countries.scss */
.bg-ruishi {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -512px -115px;
}

/* line 728, src/common/scss/sprites_countries.scss */
.bg-saipulusi {
  width: 25px;
  height: 17px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -242px -47px;
}

/* line 733, src/common/scss/sprites_countries.scss */
.bg-saisher {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -512px -150px;
}

/* line 738, src/common/scss/sprites_countries.scss */
.bg-samoya {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -512px -185px;
}

/* line 743, src/common/scss/sprites_countries.scss */
.bg-sanmalino {
  width: 25px;
  height: 17px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -242px -84px;
}

/* line 748, src/common/scss/sprites_countries.scss */
.bg-sdmhplxb {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -512px -220px;
}

/* line 753, src/common/scss/sprites_countries.scss */
.bg-shate {
  width: 25px;
  height: 17px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -242px -121px;
}

/* line 758, src/common/scss/sprites_countries.scss */
.bg-sililanka {
  width: 25px;
  height: 13px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -325px -527px;
}

/* line 763, src/common/scss/sprites_countries.scss */
.bg-sjchnws {
  width: 25px;
  height: 17px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -242px -158px;
}

/* line 768, src/common/scss/sprites_countries.scss */
.bg-slfk {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -512px -255px;
}

/* line 773, src/common/scss/sprites_countries.scss */
.bg-sllang {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -512px -290px;
}

/* line 778, src/common/scss/sprites_countries.scss */
.bg-slwny {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -512px -325px;
}

/* line 783, src/common/scss/sprites_countries.scss */
.bg-slxy {
  width: 32px;
  height: 16px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -10px -96px;
}

/* line 788, src/common/scss/sprites_countries.scss */
.bg-snjer {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -512px -360px;
}

/* line 793, src/common/scss/sprites_countries.scss */
.bg-sudan {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -512px -395px;
}

/* line 798, src/common/scss/sprites_countries.scss */
.bg-sulinan {
  width: 25px;
  height: 17px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -10px -207px;
}

/* line 803, src/common/scss/sprites_countries.scss */
.bg-suoluomen {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -512px -430px;
}

/* line 808, src/common/scss/sprites_countries.scss */
.bg-suomali {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -10px -492px;
}

/* line 813, src/common/scss/sprites_countries.scss */
.bg-swensente {
  width: 25px;
  height: 17px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -55px -207px;
}

/* line 818, src/common/scss/sprites_countries.scss */
.bg-swshlan {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -55px -492px;
}

/* line 823, src/common/scss/sprites_countries.scss */
.bg-taiguo {
  width: 25px;
  height: 17px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -100px -207px;
}

/* line 828, src/common/scss/sprites_countries.scss */
.bg-taiwan {
  width: 25px;
  height: 16px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -10px -281px;
}

/* line 833, src/common/scss/sprites_countries.scss */
.bg-tajikesitan {
  width: 25px;
  height: 13px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -370px -527px;
}

/* line 838, src/common/scss/sprites_countries.scss */
.bg-tangjia {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -100px -492px;
}

/* line 843, src/common/scss/sprites_countries.scss */
.bg-tsny {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -145px -492px;
}

/* line 848, src/common/scss/sprites_countries.scss */
.bg-tuerqi {
  width: 25px;
  height: 17px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -145px -207px;
}

/* line 853, src/common/scss/sprites_countries.scss */
.bg-tukumansitan {
  width: 25px;
  height: 13px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -415px -527px;
}

/* line 858, src/common/scss/sprites_countries.scss */
.bg-tunis {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -190px -492px;
}

/* line 863, src/common/scss/sprites_countries.scss */
.bg-tuwalu {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -235px -492px;
}

/* line 868, src/common/scss/sprites_countries.scss */
.bg-wenlai {
  width: 25px;
  height: 13px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -460px -527px;
}

/* line 873, src/common/scss/sprites_countries.scss */
.bg-wgd {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -280px -492px;
}

/* line 878, src/common/scss/sprites_countries.scss */
.bg-wnat {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -325px -492px;
}

/* line 883, src/common/scss/sprites_countries.scss */
.bg-wukelan {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -370px -492px;
}

/* line 888, src/common/scss/sprites_countries.scss */
.bg-wuzibieke {
  width: 25px;
  height: 13px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -505px -527px;
}

/* line 893, src/common/scss/sprites_countries.scss */
.bg-xijin {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -415px -492px;
}

/* line 898, src/common/scss/sprites_countries.scss */
.bg-xila {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -460px -492px;
}

/* line 903, src/common/scss/sprites_countries.scss */
.bg-xinjiapo {
  width: 25px;
  height: 17px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -190px -207px;
}

/* line 908, src/common/scss/sprites_countries.scss */
.bg-xuliya {
  width: 25px;
  height: 17px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -235px -207px;
}

/* line 913, src/common/scss/sprites_countries.scss */
.bg-xyl {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -505px -492px;
}

/* line 918, src/common/scss/sprites_countries.scss */
.bg-yamaijia {
  width: 25px;
  height: 13px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -550px -527px;
}

/* line 923, src/common/scss/sprites_countries.scss */
.bg-yameiniya {
  width: 25px;
  height: 13px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -10px -560px;
}

/* line 928, src/common/scss/sprites_countries.scss */
.bg-ydl {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -557px -10px;
}

/* line 933, src/common/scss/sprites_countries.scss */
.bg-yemen {
  width: 25px;
  height: 17px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -10px -244px;
}

/* line 938, src/common/scss/sprites_countries.scss */
.bg-yilake {
  width: 25px;
  height: 17px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -55px -244px;
}

/* line 943, src/common/scss/sprites_countries.scss */
.bg-yilang {
  width: 25px;
  height: 17px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -100px -244px;
}

/* line 948, src/common/scss/sprites_countries.scss */
.bg-yindu {
  width: 25px;
  height: 17px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -145px -244px;
}

/* line 953, src/common/scss/sprites_countries.scss */
.bg-yinni {
  width: 25px;
  height: 19px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -152px -49px;
}

/* line 958, src/common/scss/sprites_countries.scss */
.bg-yiselie {
  width: 25px;
  height: 18px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -10px -132px;
}

/* line 963, src/common/scss/sprites_countries.scss */
.bg-yuedan {
  width: 25px;
  height: 13px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -55px -560px;
}

/* line 968, src/common/scss/sprites_countries.scss */
.bg-yuenan {
  width: 25px;
  height: 17px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -190px -244px;
}

/* line 973, src/common/scss/sprites_countries.scss */
.bg-zanbiya {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -557px -45px;
}

/* line 978, src/common/scss/sprites_countries.scss */
.bg-zhade {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -557px -80px;
}

/* line 983, src/common/scss/sprites_countries.scss */
.bg-zhongfei {
  width: 25px;
  height: 15px;
  background: url("https://ai-study1.3vjia.com/prod/static/sprites_countries_210316.png") -557px -115px;
}

/* line 988, src/common/scss/sprites_countries.scss */
.bg-flag_ba {
  width: 32px;
  height: 16px;
  background: url("/assert/images/area/flag_ba.gif");
}

/* line 992, src/common/scss/sprites_countries.scss */
.bg-flag_ks {
  width: 25px;
  height: 17px;
  background: url("/assert/images/area/flag_ks.gif");
}

/* line 996, src/common/scss/sprites_countries.scss */
.bg-flag_me {
  width: 32px;
  height: 16px;
  background: url("/assert/images/area/flag_me.gif");
}

/* line 1000, src/common/scss/sprites_countries.scss */
.bg-flag_rs {
  width: 25px;
  height: 17px;
  background: url("/assert/images/area/flag_rs.gif");
}

@keyframes zoomInOn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* line 1, src/components/topbanner/topbanner.scss */
.top-banner {
  display: block;
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  overflow: hidden;
  background: #f7931e;
  font-size: 24px;
  position: relative;
  cursor: pointer;
  color: #fff;
}

/* line 13, src/components/topbanner/topbanner.scss */
.top-banner img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* line 19, src/components/topbanner/topbanner.scss */
.top-banner .btn-close {
  position: absolute;
  top: 50%;
  right: 10px;
  background: rgba(0, 0, 0, 0.178);
  color: #fff;
  font-size: 14px;
  width: 26px;
  height: 26px;
  line-height: 22px;
  border-radius: 100%;
  cursor: pointer;
  text-align: center;
  text-shadow: 0 1px 0 #888;
  font-style: normal;
  font-weight: normal;
  transform: translateY(-50%);
}

/* line 39, src/components/topbanner/topbanner.scss */
.pomition-top-banner-days {
  position: absolute;
  right: 140px;
  font-weight: 500;
  font-size: 20px;
  color: #333;
}

/* line 45, src/components/topbanner/topbanner.scss */
.pomition-top-banner-days .count {
  color: red;
  font-size: 48px;
  font-weight: 700;
}

@keyframes zoomInOn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* line 3, src/components/models/graphModal.scss */
.graph-modal .close {
  border: none;
  background: none;
  outline: none;
  position: absolute;
  right: 25px;
  top: 18px;
}

/* line 11, src/components/models/graphModal.scss */
.graph-modal .modal-dialog {
  width: 1080px;
  height: 700px;
  max-width: 1080px;
  max-height: 700px;
}

/* line 17, src/components/models/graphModal.scss */
.graph-modal .modal-content {
  padding: 0;
  border-radius: 8px;
  background: #F6F6F6;
  width: 100%;
  height: 700px;
}

/* line 24, src/components/models/graphModal.scss */
.graph-modal .modal-body {
  padding: 0;
  position: relative;
  width: 100%;
  height: 700px;
}

/* line 30, src/components/models/graphModal.scss */
.graph-modal iframe {
  border: none;
  background: transparent;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

@media screen and (max-width: 1100px) {
  /* line 41, src/components/models/graphModal.scss */
  .graph-modal .modal-dialog {
    width: 600px;
  }
}

/* line 9, src/common/scss/common.scss */
.col-xs-1-5,
.col-sm-1-5,
.col-md-1-5,
.col-lg-1-5 {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

/* line 19, src/common/scss/common.scss */
.col-xs-1-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

@media (min-width: 768px) {
  /* line 27, src/common/scss/common.scss */
  .col-sm-1-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 992px) {
  /* line 36, src/common/scss/common.scss */
  .col-md-1-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 1200px) {
  /* line 45, src/common/scss/common.scss */
  .col-lg-1-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

/* line 53, src/common/scss/common.scss */
.ah-pt32 {
  top: 32px;
}

/* line 60, src/common/scss/common.scss */
.ah-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
}

@media (max-width: 992px) {
  /* line 68, src/common/scss/common.scss */
  .ah-container {
    flex-wrap: wrap;
  }
}

/* line 73, src/common/scss/common.scss */
.container-more {
  margin-left: auto;
  display: inline-block;
  padding: 0 20px;
  font-size: 14px;
  color: #999 !important;
  line-height: 30px;
  height: 30px;
  cursor: pointer;
  border: 1px solid #d2d2d2;
  border-radius: 15px;
}

/* line 84, src/common/scss/common.scss */
.container-more:hover {
  background-color: #EDEDED;
  text-decoration: none;
}

/* line 88, src/common/scss/common.scss */
.container-more span {
  margin-left: 4px;
}

/* line 94, src/common/scss/common.scss */
.ah-btn {
  background-color: transparent;
  border-radius: 30px;
  border: 1px solid #f7931e;
  color: #666;
}

/* line 99, src/common/scss/common.scss */
.ah-btn:focus {
  outline: none;
}

/* line 102, src/common/scss/common.scss */
.ah-btn.disabled {
  pointer-events: none;
  color: #ccc !important;
  border-color: #eee !important;
  background-color: transparent !important;
}

/* line 109, src/common/scss/common.scss */
.ah-btn--xl {
  height: 60px;
  padding: 0 36px;
  font-size: 18px;
  min-width: 240px;
}

/* line 115, src/common/scss/common.scss */
.ah-btn--l {
  height: 48px;
  padding: 0 24px;
  font-size: 14px;
}

/* line 120, src/common/scss/common.scss */
.ah-btn--m {
  height: 40px;
  padding: 0 24px;
  font-size: 14px;
}

/* line 125, src/common/scss/common.scss */
.ah-btn--s {
  height: 32px;
  padding: 0 16px;
  font-size: 14px;
}

/* line 130, src/common/scss/common.scss */
.ah-btn--xs {
  height: 24px;
  padding: 0 12px;
  font-size: 14px;
}

/* line 135, src/common/scss/common.scss */
.ah-btn--rem {
  padding: 0 0.1rem;
  min-width: 3.08rem;
  height: .88rem;
  line-height: .88rem;
  font-size: 18px;
  transition: all .4s;
}

/* line 142, src/common/scss/common.scss */
.ah-btn--rem.disabled {
  background-color: #dedede;
}

/* line 147, src/common/scss/common.scss */
.ah-btn--primary {
  color: #fff;
  background-color: #f7931e;
}

/* line 150, src/common/scss/common.scss */
.ah-btn--primary:hover {
  color: #fff;
  background-color: #ffaf47;
  border-color: #ffaf47;
}

/* line 155, src/common/scss/common.scss */
.ah-btn--primary:active {
  color: #fff;
  background-color: #d1700f;
  border-color: #d1700f;
}

/* line 160, src/common/scss/common.scss */
.ah-btn--primary.disabled {
  background-color: #DEDEDE !important;
  border-color: #DEDEDE !important;
  color: #C7C7C7 !important;
  pointer-events: none;
}

/* line 168, src/common/scss/common.scss */
.ah-btn--priborder {
  color: #f7931e;
}

/* line 170, src/common/scss/common.scss */
.ah-btn--priborder:hover {
  color: #fff;
  background-color: #f7931e;
}

/* line 174, src/common/scss/common.scss */
.ah-btn--priborder:active {
  color: #fff;
  background-color: #d1700f;
  border-color: #d1700f;
}

/* line 181, src/common/scss/common.scss */
.ah-btn--default {
  border-color: #666;
}

/* line 183, src/common/scss/common.scss */
.ah-btn--default:hover {
  color: #f7931e;
  border-color: #f7931e;
}

/* line 187, src/common/scss/common.scss */
.ah-btn--default:active {
  color: #d1700f;
  border-color: #d1700f;
}

/* line 193, src/common/scss/common.scss */
.ah-btn__modal {
  border-radius: 2px;
}

/* line 197, src/common/scss/common.scss */
.ah-btn__text {
  font-size: 14px;
  color: #f7931e;
  cursor: pointer;
}

/* line 201, src/common/scss/common.scss */
.ah-btn__text:hover {
  color: #ffaf47;
}

/* line 204, src/common/scss/common.scss */
.ah-btn__text:active {
  color: #d1700f;
}

/* line 207, src/common/scss/common.scss */
.ah-btn__text.disabled {
  pointer-events: none;
  color: #ccc;
}

/* line 214, src/common/scss/common.scss */
.contact-area__try {
  color: #f7931e;
  border: 1px solid #f7931e;
  background: transparent;
}

/* line 221, src/common/scss/common.scss */
.ah-shadow,
.ah-shadow__hover {
  box-shadow: 0 2px 4px 0 rgba(81, 59, 38, 0.1);
  transition: .3s;
}

/* line 226, src/common/scss/common.scss */
.ah-shadow__hover:hover {
  box-shadow: 7px 12px 14px 0 rgba(204, 204, 204, 0.5) !important;
  transform: translateY(-2px);
}

/* line 231, src/common/scss/common.scss */
.ah-hover--shadow:hover {
  box-shadow: 4px 4px 20px 0 rgba(43, 38, 81, 0.1);
}

/* line 237, src/common/scss/common.scss */
.ah-text--bold {
  font-weight: 400 !important;
}

/* line 243, src/common/scss/common.scss */
.breadcrumb a {
  color: #666;
}

/* line 245, src/common/scss/common.scss */
.breadcrumb a:hover {
  color: #f7931e;
  text-decoration: none;
}

/* line 255, src/common/scss/common.scss */
.img-contain {
  object-fit: contain !important;
  background-color: #f4f4f4 !important;
}

/* line 261, src/common/scss/common.scss */
a {
  color: #f7931e;
  text-decoration: none;
}

/* line 264, src/common/scss/common.scss */
a:hover {
  color: #ffaf47;
  text-decoration: none;
}

/* line 268, src/common/scss/common.scss */
a:active {
  color: #d1700f;
}

/* line 273, src/common/scss/common.scss */
ul, li {
  list-style: none;
  padding: 0;
}

/* line 279, src/common/scss/common.scss */
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  background-color: #DEDEE0 !important;
  border-radius: 2px;
}

/* line 284, src/common/scss/common.scss */
.mCustomScrollBox:hover .mCSB_dragger_bar {
  background-color: #DEDEE0 !important;
}

/* line 287, src/common/scss/common.scss */
.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
}

/* line 292, src/common/scss/common.scss */
.mCSB_scrollTools {
  width: 4px;
}

/* line 295, src/common/scss/common.scss */
.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical, .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical {
  margin: 0;
}

/* line 302, src/common/scss/common.scss */
.b-lazy + .b-loading {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  transition: opacity .2s ease-in-out;
}

/* line 310, src/common/scss/common.scss */
.b-loaded {
  opacity: 1;
}

/* line 313, src/common/scss/common.scss */
.b-loaded + .b-loading {
  display: none;
}

/* line 319, src/common/scss/common.scss */
#nprogress .bar {
  background: #f7931e !important;
}

/* line 322, src/common/scss/common.scss */
#nprogress .peg {
  box-shadow: 0 0 10px #f7931e, 0 0 5px #f7931e;
}

/* line 327, src/common/scss/common.scss */
.iconfont {
  line-height: 1;
}

/* line 332, src/common/scss/common.scss */
.ui-MPreview-wrap {
  position: fixed !important;
}

/* line 334, src/common/scss/common.scss */
.ui-MPreview-wrap .icon-guan {
  position: absolute;
  top: 1.36rem;
  right: 0;
  z-index: 2;
  padding: 0.32rem;
}

/* line 341, src/common/scss/common.scss */
.ui-MPreview-wrap .ui-MPreview-toolbar {
  display: none;
}

/* line 344, src/common/scss/common.scss */
.ui-MPreview-wrap .ui-MPreview-view {
  top: 0 !important;
  bottom: 0 !important;
  height: 100% !important;
}

/* line 353, src/common/scss/common.scss */
.ahm-container {
  padding: 0 0.6rem;
}

/* line 355, src/common/scss/common.scss */
.ahm-container__title {
  margin-bottom: 0;
  font-size: 30px;
  color: #333;
  text-align: center;
  font-weight: 500;
}

/* line 362, src/common/scss/common.scss */
.ahm-container__desc {
  margin: 0.16rem 0 0;
  font-size: 14px;
  color: #666;
  text-align: center;
}

/* line 368, src/common/scss/common.scss */
.ahm-container__bd {
  margin-top: 0.64rem;
  font-size: 16px;
}

/* line 374, src/common/scss/common.scss */
.ah-btn--priborder:active {
  color: #fff;
  border-color: transparent;
  background-color: #f7931e;
}

/* line 382, src/common/scss/common.scss */
.ahm-popOver {
  display: none;
  position: absolute;
  padding-top: .2rem;
  transition: all .4s;
  z-index: 1032;
}

/* line 388, src/common/scss/common.scss */
.ahm-popOver__content {
  min-width: 2.72rem;
  height: 0;
  padding: .16rem 0;
  background-color: #fff;
  border-radius: .2rem;
  box-shadow: -0.08rem 0 0.32rem 0 rgba(43, 38, 81, 0.12);
  overflow: hidden;
  transition: all .4s;
}

/* line 397, src/common/scss/common.scss */
.ahm-popOver__content:before {
  content: "";
  position: absolute;
  top: -.18rem;
  right: .2rem;
  border: .2rem solid transparent;
  border-bottom-color: #fff;
  transition: all .4s;
}

/* line 406, src/common/scss/common.scss */
.ahm-popOver__content .popOver_link {
  display: inline-block;
  width: 100%;
  padding-left: .52rem;
  height: 1.2rem;
  line-height: 1.2rem;
  font-size: 18px;
  color: #000;
}

/* line 414, src/common/scss/common.scss */
.ahm-popOver__content .popOver_link:hover, .ahm-popOver__content .popOver_link:active {
  background-color: rgba(255, 255, 255, 0.2);
}

/* line 419, src/common/scss/common.scss */
.ahm-popOver.show {
  display: block;
}

/* line 425, src/common/scss/common.scss */
.ahm-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1031;
  cursor: pointer;
}

/* line 436, src/common/scss/common.scss */
.ahm-btn {
  display: inline-block;
  font-size: 14px;
  color: #666;
  line-height: 0.64rem;
  padding: 0 0.28rem;
  background-color: #F5F5F5;
  border-radius: 0.32rem;
  border: 1px solid #f5f5f5;
}

/* line 446, src/common/scss/common.scss */
.ahm-btn.active {
  color: #f7931e;
  border: 1px solid #f7931e;
  background-color: #fef4e8;
}

/* line 452, src/common/scss/common.scss */
.ahm-scroll::-webkit-scrollbar {
  display: none;
}

/* line 455, src/common/scss/common.scss */
.line-center {
  width: 100% !important;
  text-align: center !important;
  margin-top: 20px !important;
}
