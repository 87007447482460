$themeColor: #f7931e; // 主题色247,147,30
$themeHoverColor: #ffaf47; // 主题-hover色
$themeActiveColor: #d1700f; // 主题-active色
$themeBgColor: #fef9ee; // 主题-选择列表hover背景色
$themeBgc: #fdfaf6; // 主题-浅橙色背景色
$ossUrl: 'https://ai-study1.3vjia.com/prod/static'; // OSS地址
$fe-ossUrl: 'https://3vj-fe.3vjia.com/'; // 前端OSS地址
$mobileMax: '767px'; // 移动端尺寸
$smMobileMax: '576px'; // 小屏幕移动端尺寸

// 单行文本溢出显示省略号
@mixin text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
  word-break: break-all;
}


// 多行文本溢出显示省略号
@mixin multi-text-ellipsis($line: 2) {
  display: -webkit-box;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
  overflow: hidden;
}

//主要按钮
@mixin button__primary {
  height: 40px;
  padding-left: 30px;
  padding-right: 30px;
  background: $themeColor;
  color: #fff;
  border-radius: 20px;
  border: 1px solid $themeColor;
  outline: none;
  &:hover {
    background: $themeHoverColor;
    border: 1px solid $themeHoverColor;
  }
  &:focus {
    outline: none;
    background-color: $themeActiveColor;
  }
}

// button hover、active
@mixin button__colorChange {
  &:hover {
    color: $themeHoverColor;
  }
  &:active {
    color: $themeActiveColor;
  }
}

// 图片居中定位
@mixin img__center {
  position: absolute;
  width: 100%;
  min-height: 100%;
  top: 50%;
  transform: translateY(-50%);
  object-fit: cover;
}

// 分页器
@mixin swiper-pager {
  .swiper-pagination {
    position: unset;
    margin-top: 12px;
    .swiper-pagination-bullet {
      width: 20px;
      height: 4px;
      border-radius: 4px;
      background: #000;
      opacity: .1;
      margin-right: 12px;
      &:hover {
        opacity: 0.2;
      }
      &:focus {
        outline: unset !important;
      }
    }
    .swiper-pagination-bullet-active {
      width: 30px;
      background-color: $themeColor;
      opacity: 1;
    }
  }
}

// 放大&缩小
@keyframes zoomInOn
{
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@mixin zoomInOn() {
  animation: zoomInOn .2s;
}

// 加载中
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@mixin loading($size: 18px, $color: #999) {
  display: inline-block;
  width: $size;
  height: $size;
  margin-left: 4px;
  border: 2px solid $color;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-radius: 50%;
  animation: 0.6s loading linear infinite;
  vertical-align: sub;
}
