@import './mixin.scss';

.bg-afuhan {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -55px -281px;
}

.bg-aihouse {
    width: 25px; height: 25px;
    background: url($ossUrl + '/sprites_countries_210316.png') -55px -10px;
}

.bg-aiji {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -100px -281px;
}

.bg-airlan {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -145px -281px;
}

.bg-aiseby {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -190px -281px;
}

.bg-aishny {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -235px -281px;
}

.bg-alianqiu {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -280px -281px;
}

.bg-aman {
    width: 25px; height: 13px;
    background: url($ossUrl + '/sprites_countries_210316.png') -557px -422px;
}

.bg-andaor {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -332px -10px;
}

.bg-angla {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -332px -45px;
}

.bg-arbny {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -332px -80px;
}

.bg-arjly {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -332px -115px;
}

.bg-asaibaijiang {
    width: 25px; height: 16px;
    background: url($ossUrl + '/sprites_countries_210316.png') -235px -244px;
}

.bg-babaduosi {
    width: 25px; height: 17px;
    background: url($ossUrl + '/sprites_countries_210316.png') -55px -132px;
}

.bg-bahama {
    width: 25px; height: 16px;
    background: url($ossUrl + '/sprites_countries_210316.png') -287px -10px;
}

.bg-baie {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -332px -150px;
}

.bg-bajisitan {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -332px -185px;
}

.bg-balagui {
    width: 25px; height: 17px;
    background: url($ossUrl + '/sprites_countries_210316.png') -100px -132px;
}

.bg-balesitan {
    width: 25px; height: 13px;
    background: url($ossUrl + '/sprites_countries_210316.png') -557px -455px;
}

.bg-balin {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -332px -220px;
}

.bg-baojly {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -332px -255px;
}

.bg-baxi {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -10px -317px;
}

.bg-bbd {
    width: 25px; height: 17px;
    background: url($ossUrl + '/sprites_countries_210316.png') -145px -132px;
}

.bg-beinin {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -55px -317px;
}

.bg-bilishi {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -100px -317px;
}

.bg-bingdao {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -145px -317px;
}

.bg-bishao {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -190px -317px;
}

.bg-bjnfs {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -235px -317px;
}

.bg-blz {
    width: 25px; height: 17px;
    background: url($ossUrl + '/sprites_countries_210316.png') -197px -10px;
}

.bg-bociwana {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -280px -317px;
}

.bg-bohei {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -325px -317px;
}

.bg-bolan {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -377px -10px;
}

.bg-budan {
    width: 25px; height: 17px;
    background: url($ossUrl + '/sprites_countries_210316.png') -197px -47px;
}

.bg-bulongdi {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -377px -45px;
}

.bg-chaoxian {
    width: 25px; height: 13px;
    background: url($ossUrl + '/sprites_countries_210316.png') -557px -488px;
}

.bg-chdjny {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -377px -80px;
}

.bg-danmai {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -377px -115px;
}

.bg-dbg {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -377px -150px;
}

.bg-dmnike {
    width: 32px; height: 16px;
    background: url($ossUrl + '/sprites_countries_210316.png') -100px -10px;
}

.bg-duoge {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -377px -185px;
}

.bg-duominijia {
    width: 25px; height: 16px;
    background: url($ossUrl + '/sprites_countries_210316.png') -287px -46px;
}

.bg-egde {
    width: 32px; height: 16px;
    background: url($ossUrl + '/sprites_countries_210316.png') -100px -46px;
}

.bg-eltly {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -377px -220px;
}

.bg-eluosi {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -377px -255px;
}

.bg-fandigang {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -377px -290px;
}

.bg-feiji {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -10px -352px;
}

.bg-feilubing {
    width: 25px; height: 13px;
    background: url($ossUrl + '/sprites_countries_210316.png') -10px -527px;
}

.bg-fenlan {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -55px -352px;
}

.bg-flg_argentina_sm {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -100px -352px;
}

.bg-flg_australia_sm {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -145px -352px;
}

.bg-flg_austria_sm {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -190px -352px;
}

.bg-flg_bolivia_sm {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -235px -352px;
}

.bg-flg_brazil_sm {
    width: 25px; height: 18px;
    background: url($ossUrl + '/sprites_countries_210316.png') -152px -88px;
}

.bg-flg_canada_sm {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -280px -352px;
}

.bg-flg_chile_sm {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -325px -352px;
}

.bg-flg_china_sm {
    width: 25px; height: 17px;
    background: url($ossUrl + '/sprites_countries_210316.png') -197px -84px;
}

.bg-flg_colombia_sm {
    width: 25px; height: 14px;
    background: url($ossUrl + '/sprites_countries_210316.png') -557px -150px;
}

.bg-flg_costarica_sm {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -370px -352px;
}

.bg-flg_elsavador_sm {
    width: 25px; height: 16px;
    background: url($ossUrl + '/sprites_countries_210316.png') -287px -82px;
}

.bg-flg_france_sm {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -10px -387px;
}

.bg-flg_germany_sm {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -55px -387px;
}

.bg-flg_guetamala_sm {
    width: 25px; height: 14px;
    background: url($ossUrl + '/sprites_countries_210316.png') -557px -184px;
}

.bg-flg_honduras_sm {
    width: 25px; height: 14px;
    background: url($ossUrl + '/sprites_countries_210316.png') -557px -218px;
}

.bg-flg_mashr {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -100px -387px;
}

.bg-flg_mexico_sm {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -145px -387px;
}

.bg-flg_netherlands_sm {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -190px -387px;
}

.bg-flg_newzealand_sm {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -235px -387px;
}

.bg-flg_nicaragua_sm {
    width: 25px; height: 14px;
    background: url($ossUrl + '/sprites_countries_210316.png') -557px -252px;
}

.bg-flg_panama_sm {
    width: 25px; height: 14px;
    background: url($ossUrl + '/sprites_countries_210316.png') -557px -286px;
}

.bg-flg_peru_sm {
    width: 25px; height: 14px;
    background: url($ossUrl + '/sprites_countries_210316.png') -557px -320px;
}

.bg-flg_portugal_sm {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -280px -387px;
}

.bg-flg_romania_sm {
    width: 25px; height: 17px;
    background: url($ossUrl + '/sprites_countries_210316.png') -197px -121px;
}

.bg-flg_spain_sm {
    width: 25px; height: 19px;
    background: url($ossUrl + '/sprites_countries_210316.png') -152px -10px;
}

.bg-flg_uk_sm {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -325px -387px;
}

.bg-flg_uruguay_sm {
    width: 25px; height: 14px;
    background: url($ossUrl + '/sprites_countries_210316.png') -557px -354px;
}

.bg-flg_usa_sm {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -370px -387px;
}

.bg-flg_venezuela_sm {
    width: 25px; height: 13px;
    background: url($ossUrl + '/sprites_countries_210316.png') -55px -527px;
}

.bg-fodej {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -422px -10px;
}

.bg-gangguo {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -422px -45px;
}

.bg-gbya {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -422px -80px;
}

.bg-gelinnada {
    width: 33px; height: 16px;
    background: url($ossUrl + '/sprites_countries_210316.png') -10px -60px;
}

.bg-gelujiya {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -422px -115px;
}

.bg-guba {
    width: 31px; height: 16px;
    background: url($ossUrl + '/sprites_countries_210316.png') -62px -96px;
}

.bg-guiyana {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -422px -150px;
}

.bg-haidi {
    width: 25px; height: 17px;
    background: url($ossUrl + '/sprites_countries_210316.png') -10px -170px;
}

.bg-hanguo {
    width: 25px; height: 17px;
    background: url($ossUrl + '/sprites_countries_210316.png') -55px -170px;
}

.bg-hasakesitan {
    width: 25px; height: 13px;
    background: url($ossUrl + '/sprites_countries_210316.png') -100px -527px;
}

.bg-hongkong {
    width: 25px; height: 16px;
    background: url($ossUrl + '/sprites_countries_210316.png') -287px -118px;
}

.bg-jbbw {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -422px -185px;
}

.bg-jiana {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -422px -220px;
}

.bg-jianpuzai {
    width: 25px; height: 16px;
    background: url($ossUrl + '/sprites_countries_210316.png') -287px -154px;
}

.bg-jiapeng {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -422px -255px;
}

.bg-jibuti {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -422px -290px;
}

.bg-jieke {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -422px -325px;
}

.bg-jiererjisitan {
    width: 16px; height: 11px;
    background: url($ossUrl + '/sprites_countries_210316.png') -63px -60px;
}

.bg-jineiya {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -422px -360px;
}

.bg-jlbs {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -10px -422px;
}

.bg-kamailong {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -55px -422px;
}

.bg-kataer {
    width: 25px; height: 13px;
    background: url($ossUrl + '/sprites_countries_210316.png') -145px -527px;
}

.bg-kemoluo {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -100px -422px;
}

.bg-keniya {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -145px -422px;
}

.bg-ketediwa {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -190px -422px;
}

.bg-keweite {
    width: 25px; height: 13px;
    background: url($ossUrl + '/sprites_countries_210316.png') -190px -527px;
}

.bg-kldy {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -235px -422px;
}

.bg-laisuotuo {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -280px -422px;
}

.bg-laowo {
    width: 25px; height: 17px;
    background: url($ossUrl + '/sprites_countries_210316.png') -100px -170px;
}

.bg-latwy {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -325px -422px;
}

.bg-libaneng {
    width: 25px; height: 17px;
    background: url($ossUrl + '/sprites_countries_210316.png') -145px -170px;
}

.bg-libia {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -370px -422px;
}

.bg-libiliya {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -415px -422px;
}

.bg-liezdsd {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -467px -10px;
}

.bg-litaow {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -467px -45px;
}

.bg-lusbao {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -467px -80px;
}

.bg-luwangda {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -467px -115px;
}

.bg-macao {
    width: 25px; height: 16px;
    background: url($ossUrl + '/sprites_countries_210316.png') -287px -190px;
}

.bg-maerdaifu {
    width: 25px; height: 17px;
    background: url($ossUrl + '/sprites_countries_210316.png') -190px -170px;
}

.bg-malaixiya {
    width: 25px; height: 13px;
    background: url($ossUrl + '/sprites_countries_210316.png') -235px -527px;
}

.bg-malawei {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -467px -150px;
}

.bg-mali {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -467px -185px;
}

.bg-maqidun {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -467px -220px;
}

.bg-mart {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -467px -255px;
}

.bg-mdjsj {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -467px -290px;
}

.bg-memgjiala {
    width: 25px; height: 16px;
    background: url($ossUrl + '/sprites_countries_210316.png') -287px -226px;
}

.bg-menggu {
    width: 25px; height: 13px;
    background: url($ossUrl + '/sprites_countries_210316.png') -280px -527px;
}

.bg-miandian {
    width: 25px; height: 14px;
    background: url($ossUrl + '/sprites_countries_210316.png') -557px -388px;
}

.bg-minzhugg {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -467px -325px;
}

.bg-mklnxy {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -467px -360px;
}

.bg-mlqs {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -467px -395px;
}

.bg-mltny {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -10px -457px;
}

.bg-molog {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -55px -457px;
}

.bg-monage {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -100px -457px;
}

.bg-mordw {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -145px -457px;
}

.bg-msbk {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -190px -457px;
}

.bg-nanfei {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -235px -457px;
}

.bg-naolu {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -280px -457px;
}

.bg-niboer {
    width: 25px; height: 30px;
    background: url($ossUrl + '/sprites_countries_210316.png') -10px -10px;
}

.bg-niriliya {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -325px -457px;
}

.bg-nirir {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -370px -457px;
}

.bg-nmby {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -415px -457px;
}

.bg-nslf {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -460px -457px;
}

.bg-nuowei {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -512px -10px;
}

.bg-palao {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -512px -45px;
}

.bg-riben {
    width: 25px; height: 17px;
    background: url($ossUrl + '/sprites_countries_210316.png') -242px -10px;
}

.bg-ruidian {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -512px -80px;
}

.bg-ruishi {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -512px -115px;
}

.bg-saipulusi {
    width: 25px; height: 17px;
    background: url($ossUrl + '/sprites_countries_210316.png') -242px -47px;
}

.bg-saisher {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -512px -150px;
}

.bg-samoya {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -512px -185px;
}

.bg-sanmalino {
    width: 25px; height: 17px;
    background: url($ossUrl + '/sprites_countries_210316.png') -242px -84px;
}

.bg-sdmhplxb {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -512px -220px;
}

.bg-shate {
    width: 25px; height: 17px;
    background: url($ossUrl + '/sprites_countries_210316.png') -242px -121px;
}

.bg-sililanka {
    width: 25px; height: 13px;
    background: url($ossUrl + '/sprites_countries_210316.png') -325px -527px;
}

.bg-sjchnws {
    width: 25px; height: 17px;
    background: url($ossUrl + '/sprites_countries_210316.png') -242px -158px;
}

.bg-slfk {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -512px -255px;
}

.bg-sllang {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -512px -290px;
}

.bg-slwny {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -512px -325px;
}

.bg-slxy {
    width: 32px; height: 16px;
    background: url($ossUrl + '/sprites_countries_210316.png') -10px -96px;
}

.bg-snjer {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -512px -360px;
}

.bg-sudan {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -512px -395px;
}

.bg-sulinan {
    width: 25px; height: 17px;
    background: url($ossUrl + '/sprites_countries_210316.png') -10px -207px;
}

.bg-suoluomen {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -512px -430px;
}

.bg-suomali {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -10px -492px;
}

.bg-swensente {
    width: 25px; height: 17px;
    background: url($ossUrl + '/sprites_countries_210316.png') -55px -207px;
}

.bg-swshlan {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -55px -492px;
}

.bg-taiguo {
    width: 25px; height: 17px;
    background: url($ossUrl + '/sprites_countries_210316.png') -100px -207px;
}

.bg-taiwan {
    width: 25px; height: 16px;
    background: url($ossUrl + '/sprites_countries_210316.png') -10px -281px;
}

.bg-tajikesitan {
    width: 25px; height: 13px;
    background: url($ossUrl + '/sprites_countries_210316.png') -370px -527px;
}

.bg-tangjia {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -100px -492px;
}

.bg-tsny {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -145px -492px;
}

.bg-tuerqi {
    width: 25px; height: 17px;
    background: url($ossUrl + '/sprites_countries_210316.png') -145px -207px;
}

.bg-tukumansitan {
    width: 25px; height: 13px;
    background: url($ossUrl + '/sprites_countries_210316.png') -415px -527px;
}

.bg-tunis {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -190px -492px;
}

.bg-tuwalu {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -235px -492px;
}

.bg-wenlai {
    width: 25px; height: 13px;
    background: url($ossUrl + '/sprites_countries_210316.png') -460px -527px;
}

.bg-wgd {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -280px -492px;
}

.bg-wnat {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -325px -492px;
}

.bg-wukelan {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -370px -492px;
}

.bg-wuzibieke {
    width: 25px; height: 13px;
    background: url($ossUrl + '/sprites_countries_210316.png') -505px -527px;
}

.bg-xijin {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -415px -492px;
}

.bg-xila {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -460px -492px;
}

.bg-xinjiapo {
    width: 25px; height: 17px;
    background: url($ossUrl + '/sprites_countries_210316.png') -190px -207px;
}

.bg-xuliya {
    width: 25px; height: 17px;
    background: url($ossUrl + '/sprites_countries_210316.png') -235px -207px;
}

.bg-xyl {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -505px -492px;
}

.bg-yamaijia {
    width: 25px; height: 13px;
    background: url($ossUrl + '/sprites_countries_210316.png') -550px -527px;
}

.bg-yameiniya {
    width: 25px; height: 13px;
    background: url($ossUrl + '/sprites_countries_210316.png') -10px -560px;
}

.bg-ydl {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -557px -10px;
}

.bg-yemen {
    width: 25px; height: 17px;
    background: url($ossUrl + '/sprites_countries_210316.png') -10px -244px;
}

.bg-yilake {
    width: 25px; height: 17px;
    background: url($ossUrl + '/sprites_countries_210316.png') -55px -244px;
}

.bg-yilang {
    width: 25px; height: 17px;
    background: url($ossUrl + '/sprites_countries_210316.png') -100px -244px;
}

.bg-yindu {
    width: 25px; height: 17px;
    background: url($ossUrl + '/sprites_countries_210316.png') -145px -244px;
}

.bg-yinni {
    width: 25px; height: 19px;
    background: url($ossUrl + '/sprites_countries_210316.png') -152px -49px;
}

.bg-yiselie {
    width: 25px; height: 18px;
    background: url($ossUrl + '/sprites_countries_210316.png') -10px -132px;
}

.bg-yuedan {
    width: 25px; height: 13px;
    background: url($ossUrl + '/sprites_countries_210316.png') -55px -560px;
}

.bg-yuenan {
    width: 25px; height: 17px;
    background: url($ossUrl + '/sprites_countries_210316.png') -190px -244px;
}

.bg-zanbiya {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -557px -45px;
}

.bg-zhade {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -557px -80px;
}

.bg-zhongfei {
    width: 25px; height: 15px;
    background: url($ossUrl + '/sprites_countries_210316.png') -557px -115px;
}

.bg-flag_ba {
    width: 32px; height: 16px;
    background: url('/assert/images/area/flag_ba.gif');
}
.bg-flag_ks {
    width: 25px; height: 17px;
    background: url('/assert/images/area/flag_ks.gif');
}
.bg-flag_me {
    width: 32px; height: 16px;
    background: url('/assert/images/area/flag_me.gif');
}
.bg-flag_rs {
    width: 25px; height: 17px;
    background: url('/assert/images/area/flag_rs.gif');
}