@import '../../common/scss/sprites_countries.scss';
@import './mixin.scss';
@import '../../components/topbanner/topbanner.scss';
@import '../../components/models/graphModal.scss';

// 布局样式 start

  // 五等分
  .col-xs-1-5,
  .col-sm-1-5,
  .col-md-1-5,
  .col-lg-1-5 {
    position: relative;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
  }

  .col-xs-1-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  @media (min-width: 768px) {
    .col-sm-1-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%;
    }
  }

  @media (min-width: 992px) {
    .col-md-1-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%;
    }
  }

  @media (min-width: 1200px) {
    .col-lg-1-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%;
    }
  }

  .ah-pt32 {
    top: 32px;
  }

// 布局样式 end

  //头部container
  .ah-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px;
  }
  @media (max-width: 992px) {
    .ah-container {
      flex-wrap: wrap;
    }
  }
  // more按钮
  .container-more {
    margin-left: auto;
    display: inline-block;
    padding: 0 20px;
    font-size: 14px;
    color: #999!important;
    line-height: 30px;
    height: 30px;
    cursor: pointer;
    border: 1px solid #d2d2d2;
    border-radius: 15px;
    &:hover {
      background-color: #EDEDED;
      text-decoration: none;
    }
    span {
      margin-left: 4px;
    }
  }

  // 按钮
  .ah-btn {
    background-color: transparent;
    border-radius: 30px;
    border: 1px solid $themeColor;
    color: #666;
    &:focus {
      outline: none;
    }
    &.disabled {
      pointer-events: none;
      color: #ccc !important;
      border-color: #eee !important;
      background-color: transparent !important;
    }
  }
  .ah-btn--xl {
    height: 60px;
    padding: 0 36px;
    font-size: 18px;
    min-width: 240px;
  }
  .ah-btn--l {
    height: 48px;
    padding: 0 24px;
    font-size: 14px;
  }
  .ah-btn--m {
    height: 40px;
    padding: 0 24px;
    font-size: 14px;
  }
  .ah-btn--s {
    height: 32px;
    padding: 0 16px;
    font-size: 14px;
  }
  .ah-btn--xs {
    height: 24px;
    padding: 0 12px;
    font-size: 14px;
  }
  .ah-btn--rem {
    padding: 0 0.1rem;
    min-width: 3.08rem;
    height: .88rem;
    line-height: .88rem;
    font-size: 18px;
    transition: all .4s;
    &.disabled {
      background-color: #dedede;
    }
  }
  // 主要按钮-实心
  .ah-btn--primary {
    color: #fff;
    background-color: $themeColor;
    &:hover {
      color: #fff;
      background-color: $themeHoverColor;
      border-color: $themeHoverColor;
    }
    &:active {
      color: #fff;
      background-color: $themeActiveColor;
      border-color: $themeActiveColor;
    }
    &.disabled {
      background-color: #DEDEDE !important;
      border-color: #DEDEDE !important;
      color: #C7C7C7 !important;
      pointer-events: none;
    }
  }
  // 主要按钮-蓝色描边
  .ah-btn--priborder {
    color: $themeColor;
    &:hover {
      color: #fff;
      background-color: $themeColor;
    }
    &:active {
      color: #fff;
      background-color: $themeActiveColor;
      border-color: $themeActiveColor;
    }
  }
  // 次要按钮
  .ah-btn--default {
    border-color: #666;
    &:hover {
      color: $themeColor;
      border-color: $themeColor;
    }
    &:active {
      color: $themeActiveColor;
      border-color: $themeActiveColor;
    }
  }
  // 弹窗按钮
  .ah-btn__modal {
    border-radius: 2px;
  }
  // 文字按钮
  .ah-btn__text {
    font-size: 14px;
    color: $themeColor;
    cursor: pointer;
    &:hover {
      color: $themeHoverColor;
    }
    &:active {
      color: $themeActiveColor;
    }
    &.disabled {
      pointer-events: none;
      color: #ccc;
    }
  }

  //free trial
  .contact-area__try{
    color:$themeColor;
    border: 1px solid $themeColor;
    background: transparent;
  }

  // hover阴影
  .ah-shadow,
  .ah-shadow__hover{
    box-shadow: 0 2px 4px 0 rgba(81, 59, 38, 0.1);
    transition: .3s;
  }
  .ah-shadow__hover:hover {
    box-shadow: 7px 12px 14px 0 hsla(0,0%,80%,.5) !important;
    transform: translateY(-2px);
  }
  .ah-hover--shadow {
    &:hover {
      box-shadow: 4px 4px 20px 0 rgba(43, 38, 81, 0.1);
    }
  }

  // 展示页标题粗体
  .ah-text--bold {
    font-weight: 400 !important;
  }

  // 面包屑
  .breadcrumb {
    a {
      color: #666;
      &:hover {
        color: $themeColor;
        text-decoration: none;
      }
    }
  }

// 基本组件样式 end

// 图片基础样式
  .img-contain {
    object-fit: contain !important;
    background-color: #f4f4f4 !important;
  }

// 覆盖样式 start
  a {
    color: $themeColor;
    text-decoration: none;
    &:hover {
      color: $themeHoverColor;
      text-decoration: none;
    }
    &:active {
      color: $themeActiveColor;
    }
  }

  ul, li {
    list-style: none;
    padding: 0;
  }

  // 自动义滚动条样式
  .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 6px;
    background-color: #DEDEE0 !important;
    border-radius:2px;
  }
  .mCustomScrollBox:hover .mCSB_dragger_bar{
    background-color: #DEDEE0 !important;
  }
  .mCSB_scrollTools .mCSB_draggerRail {
    background-color: transparent;
  }

  // 滚动条
  .mCSB_scrollTools {
    width: 4px;
  }
  .mCSB_outside+.mCS-minimal-dark.mCSB_scrollTools_vertical, .mCSB_outside+.mCS-minimal.mCSB_scrollTools_vertical {
    margin: 0;
  }

  // 懒加载
  .b-lazy {

    & + .b-loading {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      transition: opacity .2s ease-in-out;
    }
  }
  .b-loaded {
    opacity: 1;

    & + .b-loading {
      display: none;
    }
  }

  // 进度条
  #nprogress .bar {
    background: $themeColor !important;
  }
  #nprogress .peg {
    box-shadow: 0 0 10px $themeColor, 0 0 5px $themeColor;
  }

  // iconfont
  .iconfont {
    line-height: 1;
  }

  // 图片放大组件
  .ui-MPreview-wrap {
    position: fixed !important;
    .icon-guan {
      position: absolute;
      top: 1.36rem;
      right: 0;
      z-index: 2;
      padding: 0.32rem;
    }
    .ui-MPreview-toolbar {
      display: none;
    }
    .ui-MPreview-view {
      top: 0 !important;
      bottom: 0 !important;
      height: 100% !important;
    }
  }
  // 覆盖样式 end

  // 移动端
  .ahm-container {
    padding: 0 0.6rem;
    &__title {
      margin-bottom: 0;
      font-size: 30px;
      color: #333;
      text-align: center;
      font-weight: 500;
    }
    &__desc {
      margin: 0.16rem 0 0;
      font-size: 14px;
      color: #666;
      text-align: center;
    }
    &__bd {
      margin-top: 0.64rem;
      font-size: 16px;
    }
  }
  .ah-btn--priborder {
    &:active {
      color: #fff;
      border-color: transparent;
      background-color: $themeColor;
    }
  }

  // 移动端组件
  .ahm-popOver {
    display: none;
    position: absolute;
    padding-top: .2rem;
    transition: all .4s;
    z-index: 1032;
    &__content {
      min-width: 2.72rem;
      height: 0;
      padding: .16rem 0;
      background-color: #fff;
      border-radius: .2rem;
      box-shadow: -0.08rem 0 0.32rem 0 rgba(43,38,81,.12);
      overflow: hidden;
      transition: all .4s;
      &:before {
        content: "";
        position: absolute;
        top: -.18rem;
        right: .2rem;
        border: .2rem solid transparent;
        border-bottom-color: #fff;
        transition: all .4s;
      }
      .popOver_link {
        display: inline-block;
        width: 100%;
        padding-left: .52rem;
        height: 1.2rem;
        line-height: 1.2rem;
        font-size: 18px;
        color: #000;
        &:hover, &:active {
          background-color: rgba(255, 255, 255, .2);
        }
      }
    }
    &.show {
      display: block;
    }
  }

  // 模态框--无色
  .ahm-modal-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1031;
    cursor: pointer;
  }

  // 移动端按钮
  .ahm-btn {
    display: inline-block;
    font-size: 14px;
    color: #666;
    line-height: 0.64rem;
    padding: 0 0.28rem;
    background-color: #F5F5F5;
    border-radius: 0.32rem;
    border: 1px solid #f5f5f5;
  }
  .ahm-btn.active {
    color: $themeColor;
    border: 1px solid $themeColor;
    background-color: #fef4e8;
  }
  // 隐藏滚动条
  .ahm-scroll::-webkit-scrollbar {
    display: none;
  }
  .line-center {
    width:100% !important;
    text-align: center !important;
    margin-top: 20px !important;
  }
