.top-banner{
    display: block;
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    overflow: hidden;
    background: #f7931e;
    font-size: 24px;
    position: relative;
    cursor: pointer;
    color: #fff;
    img{
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .btn-close{
        position: absolute;
        top:50%;
        right: 10px;
        background: rgba(0, 0, 0, 0.178);
        color: #fff;
        font-size: 14px;
        width: 26px;
        height: 26px;
        line-height: 22px;
        border-radius: 100%;
        cursor: pointer;
        text-align: center;        
        text-shadow: 0 1px 0 #888;
        font-style: normal;
        font-weight: normal;
        transform: translateY(-50%);
    }
}

.pomition-top-banner-days{
    position: absolute;
    right: 140px;
    font-weight: 500;
    font-size: 20px;
    color: #333;
    .count{
        color: red;
        font-size: 48px;
        font-weight: 700;
    }
}