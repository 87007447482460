@import '../../common/scss/mixin.scss';
.graph-modal{
    .close{
        border: none;
        background: none;
        outline: none;
        position: absolute;
        right: 25px;
        top: 18px;
    }
    .modal-dialog{
        width: 1080px;
        height: 700px;
        max-width: 1080px;
        max-height: 700px;     
    }  
    .modal-content{
        padding: 0;
        border-radius: 8px;
        background: #F6F6F6;
        width: 100%;
        height: 700px;
    }
    .modal-body{
        padding: 0;
        position: relative;
        width: 100%;
        height: 700px;            
    }
    iframe{
        border:none;
        background: transparent;
        width: 100%;
        height: 100%;
        border-radius: 8px;
    }    
}


@media screen and (max-width: 1100px) {
    .graph-modal .modal-dialog{
        width: 600px;
    }
}